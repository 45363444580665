export const drafrData = {
  drafrList: [
    { name: "报告名称", text: "name" },
    { name: "报告类型", text: "fileTypeName" },
    { name: "创建人", text: "userName" },
  ],
  showStudent: true,
  beforeRouteName: "UpreportDraft",
  currentPage: 1,
  pageSize: 10,
  total: 0,
  loading: true,
  spreadTable: [],
  tableHeight: 400,
  operation: ["编辑", "删除", "查看"],
  pageType: 1,
  lastState: 1000,
  filetype: null,
  financeRadio: "证券",
  courseType: 0,
  disabled: false,
  reportList:[]
};
