<template>
  <div class="gorp">
    <div class="box-card">
      <!-- <div style="margin-bottom: 10px"><i class="card-line"></i>草稿箱</div> -->
      <!-- 外汇table切换暂时不用 -->

      <div v-if="[1, 2, 3, 5, 4 ].indexOf(courseType) === -1">
        <el-radio-group v-model="financeRadio" :disabled="disabled" size="small" @change="getDrafrList" style="margin:10px -10px;" text-color="#3B6EFB" fill="#FFFF">
          <el-radio-button label="证券" value="证券"/>
          <el-radio-button label="外汇" value="外汇"/>
        </el-radio-group>
      </div>
      <div>
        <div style="margin-bottom: 20px;float: left;"><i class="card-line"></i>草稿箱</div>
        <div v-for="(item ,index) in reportList" :key="item.key" @click="tabBtn(item.router)" style="float: right;"><el-button v-if="item.name != '草稿箱'" size="mini" :type="item.name == '新建'?'warning':'primary'" style="margin-right: 10px;"> {{ item.name }} </el-button></div>
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :beforeRouteName="beforeRouteName" :operation="operation" :showStudent="showStudent" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { drafrData } from "./drafrData";
import { clickButtons, currentChanges, sizeChanges, getDrafrLists } from "./drafrMethods";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    const route = useRoute();
    onMounted(() => {
      if(sessionStorage.getItem('reportList')){
        data.reportList=JSON.parse(sessionStorage.getItem('reportList'))
      }
      data.courseType = JSON.parse(sessionStorage.getItem('courseType'))
      if (route.query.financeRadio) {
        data.financeRadio = route.query.financeRadio
      }
      getDrafrList()
      data.showStudent = JSON.parse(sessionStorage.getItem('user')).userRoleName === '教师' ? false : true
    })
    let data = reactive(drafrData)
    let getDrafrList = () => { getDrafrLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    const tabBtn=(url)=>{
      router.push(url)
    }
    return {
      ...toRefs(data),
      getDrafrList,
      sizeChange,
      currentChange,
      clickButton,
      tabBtn
    }
  }
})
</script>

<style scoped lang="less">
.gorp{
  height: calc(100vh - 320px);
  overflow: auto;
}
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
::v-deep.el-radio-button{
  .el-radio-button__inner {
    background: #FFFF;
    color:#333;
    font-size: 16px !important;
    border: 0 !important;
  }
}
</style>